export const Breadrum=()=>{
    return(
      <div className="Breadrum" style={{backgroundImage:'url(images/breadservice.jpg)'}}>
         <div className='content' style={{zIndex:'1200'}}>
                    <h1 className='animate__animated animate__fadeInUp servicebreadrumtitle'>
Recruitment &
Development</h1>
<div className="elementor-divider">
<p className="elementor-divider-separator">
						</p>
</div>
                    <p  className='animate__animated animate__fadeInUp servicebreadrumpara'>Since the exceptionally starting of our commerce, we’ve been interfacing our clients with difficult discoverability within the Data Innovation space. Our recruitment consulting firm ability ranges over a wide cluster of subdomains counting Recruitment & Development</p>
                </div>
       <div className="Breadrum-overlay"></div>
      </div>
    )
}